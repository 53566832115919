.appStoreBar {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  background: #d00254;
  padding: 12px 10px;
  position: relative;

  .close {
    position: absolute;
    right: 30px;
    font-size: 40px;
    color: #ffffff;
    cursor: pointer;
  }

  .text {
    color: #ffffff;
    line-height: 20.93px;
    font-weight: 600;
  }

  .btns {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    a {
      display: flex;
      align-items: center;
      img {
        cursor: pointer;
        width: 105px;
        height: 31px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .appStoreBar {
    .close {
      right: 10px;
      font-size: 30px;
    }
  }
}

@media screen and (max-width: 480px) {
  .appStoreBar {
    padding: 10px 10px;

    .text {
      display: none;
    }

    .btns {
      gap: 10px;
    }
  }
}