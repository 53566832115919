.container {
  width: 100%;
  min-height: 100vh;
  background-color: rgb(243, 244, 246/1);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  padding-top: 100px;

  .card {
    background-color: #ffffff;
    border-radius: 5px;
    border: 1px solid rgb(225, 225, 225/1);
    border-right: 0px;
    display: flex;

    .left {
      width: 100%;
      max-width: 240px;
      min-width: 240px;
      padding: 25px;
      border-right: 1px solid rgb(225, 225, 225/1);
      position: relative;

      .employeeImg {
        width: 50px;
        height: 50px;
        margin-bottom: 12px;
        border-radius: 50%;
      }

      .name {
        font-size: 14px;
        color: #4b5563;
      }

      .service {
        color: #111827;
        font-size: 24px;
        margin-bottom: 25px;
      }

      .time {
        display: flex;
        align-items: center;
        gap: 8px;

        img {
          width: 16px;
          height: 16px;
        }

        p {
          color: #4b5563;
          font-size: 14px;
          line-height: 0.1px;
        }
      }

      .leftArrow {
        position: absolute;
        bottom: 35px;
      }
    }

    .middle {
      flex-grow: 2;
      padding: 15px 25px;
      width: 100%;
      max-width: 700px;
      min-width: 500px;
      border-right: 1px solid rgb(225, 225, 225/1);

      .calendarContainer {
        .cal {
          border: none !important;
        }
      }
    }

    .right {
      width: 100%;
      max-width: 260px;
      min-width: 260px;
      padding: 25px;
      border-right: 1px solid rgb(225, 225, 225/1);
      display: flex;
      flex-direction: column;

      .day {
        p {
          font-weight: 500;
          color: #888888;
          margin-bottom: 35px;

          strong {
            color: #292929;
          }
        }

        ul {
          list-style: none;
          padding: 0px;
          display: flex;
          flex-direction: column;
          gap: 15px;
          overflow-y: auto;
          height: auto;
          max-height: 440px;

          li {
            border: 1px solid rgb(225, 225, 225/1);
            font-size: 18px;
            color: #1a1a1a;
            padding: 8px;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            cursor: pointer;

            &:hover {
              background-color: #111827;
              color: #ffffff;
            }

            transition: all 350ms ease-in-out;
            -webkit-animation: slide 1000ms forwards;
            animation: slide 1000ms forwards;
            opacity: 0;
            @-webkit-keyframes slide {
              100% {
                opacity: 1;
              }
            }

            @keyframes slide {
              100% {
                opacity: 1;
              }
            }
          }

          .activeSlot {
            color: #ffffff;
            background-color: #111827;
          }

          /* width */
          &::-webkit-scrollbar {
            width: 3px;
          }

          /* Track */
          &::-webkit-scrollbar-track {
            background: #f1f1f1;
          }

          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: #888;
          }

          /* Handle on hover */
          &::-webkit-scrollbar-thumb:hover {
            background: #555;
          }
        }
      }
    }
  }

  .scheduleBtn {
    margin: 20px;
    width: 100%;
    max-width: 500px;
    padding: 18px 16px;
    border: none;
    background-color: #000000;
    border-radius: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      font-size: 24px;
      font-weight: 600;
      color: #ffffff;
      margin-bottom: -3px;
      margin-right: 10px;
      font-family: 'ploni', sans-serif;
    }

    &:hover {
      opacity: 0.9;
    }
  }

  .modalContainer {
    width: 100%;
    height: 100%;
    position: relative;

    .close {
      position: absolute;
      top: -10px;
      right: 0px;
      font-size: 25px;
      z-index: 999999;
    }

    .lottie {
      // transform: translateY(-120px);
    }

    .date {
      // transform: translateY(-120px);
      text-align: center;
      margin-bottom: 25px;
      margin-top: 10px;
      font-size: 24px;
    }

    .details {
      // transform: translateY(-120px);
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 25px;

      .block {
        width: 45%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        margin-bottom: 10px;
        background-color: #f2f7fc;
        border: 1px solid #5555550a;
        padding: 10px;
        border-radius: 10px;

        h3{
          font-size: 16px;
        }
      }
    }
    .comment {
      // transform: translateY(-130px);
      margin: 20px 0;

      label {
        font-weight: bold;
        font-size: 20px;
      }
      input {
        margin-top: 10px;
        width: 100%;
        height: 50px;
        border-radius: 5px;
        border: 1px solid #888888;
        outline: none;
        padding: 10px 15px;
      }
    }

    .btns {
      // transform: translateY(-112px);
      display: flex;
      justify-content: space-between;
      gap: 15px;
   
      // margin-bottom: -110px;

      button {
        width: 100%;
        padding: 16px 16px;
        border: none;
        background-color: #00a25a;
        border-radius: 5px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        p {
          display: flex;
          align-items: center;
          font-size: 28px;
          font-weight: 600;
          color: #ffffff;
          gap: 10px;
          position: relative;

          img {
            position: absolute;
            right: -34px;
          }
        }

        &:hover {
          opacity: 0.9;
        }

        .dropdown {
          position: absolute;
          width: 100%;
          top: 50px;
          height: auto;
          background-color: #111827;
          border-radius: 0px 0px 5px 5px;
          border-top: 1px solid #f1f1f16e;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          direction: ltr;
          padding: 20px 15px;

          ul {
            list-style: none;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 15px;
            li {
              color: #f1f1f1;
            }
          }
        }
      }
    }
    .downloadApp {
      margin-top: 15px;
      p {
        text-align: center;
        margin-bottom: 20px;
      }
      .store {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        img {
          width: 140px;
        }
      }
    }

    .waitingModalWrapper {
      height: 250px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
    }

    // for waiting list modal

  }
}

@media screen and (max-width: 1100px) {
  .container {
    .card {
      .left {
        min-width: 200px;
      }

      .middle {
        min-width: 350px;
      }

      .right {
        min-width: 200px;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .container {
    .card {
      .left {
        min-width: 170px;
        padding: 15px;
      }

      .middle {
        min-width: 350px;
        padding: 15px;
      }

      .right {
        min-width: 200px;
        padding: 15px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding: 0px 0px 80px 0px;
    background-color: #ffffff;

    .card {
      flex-direction: column;
      min-width: 100%;
      border-bottom: none;

      .left {
        min-width: 100%;
        max-width: 100%;
        padding: 20px;

        .service {
          font-size: 22px;
          margin-bottom: 15px;
        }

        .leftArrow {
          left: 25px;
          top: 30px;
          bottom: unset;
        }
      }

      .middle {
        min-width: 100%;
        max-width: 100%;
        padding: 20px 10px;
        border-right: none;
        border-bottom: 1px solid rgb(225, 225, 225/1);
        border-top: 1px solid rgb(225, 225, 225/1);
      }

      .right {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        padding: 20px;
        
        .day {
          
          p {
            margin-bottom: 19px;
          }
          ul {
            flex-direction: row;
            flex-wrap: wrap;
            align-content: flex-start;
            justify-content: center;
            li {
              width: 30%;
            }
          }
        }
      }
    }

    .scheduleBtn {
      position: fixed;
      bottom: 0px;
      padding: 22px 16px;
      max-width: 90%;

      p {
        font-size: 1.4rem;
        letter-spacing: 0.5px;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    .modalContainer {
      .details {
        margin-top: 10px;
      }
      .btns {
        button {
          p {
            img {
              width: 20px;
              height: 20px;
              right: -27px;
            }
          }
        }
      }
    }
  }
}
