.mainContainer {
  height: 100vh;
  width: 100%;
  position: fixed;
  padding: 20px;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;

  .backdrop {
    height: 100vh;
    width: 100%;
    position: fixed;
    left: 0px;
    top: 0px;
    backdrop-filter: brightness(40%) blur(3px);
    -webkit-backdrop-filter: brightness(40%) blur(3px);
  }

  .container {
    // padding: 20px 20px;
    position: relative;

    border-radius: 0.5rem;
    text-align: center;
    background-color: #f8faff;
    border: solid 0.5px #e0e9fe;
    width: 95%;
    max-width: 500px;
    min-height: 22vh;
    max-height: 75vh;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    .content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .lottie {
        margin-top: -50px;
        background-color: #ffff;
        padding: 20px;
        border-radius: 50px;
        border: 1px solid #c7cede5e;
      }

      .text {
        padding: 1rem;

        h1 {
          font-size: 2rem;
          font-weight: 800;
          color: #20304f;
          margin-top: 0;
          margin-bottom: 10px;
        }

        span {
          font-size: 1.2rem;
          font-weight: 300;
        }
      }

      a {
        background: #2f2e65;
        border: 2px solid #2f2e65;
        color: #fafaff;
        padding: 10px;
        font-size: 1.2rem;
        text-align: center;
        border-radius: 0.3rem;
        font-weight: 500;
        margin-bottom: 20px;
        cursor: pointer;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .mainContainer {
    align-items: center;
    z-index: 999999;
    .container {
      max-width: 500px;
      border-radius: 20px;
    }
  }
}
