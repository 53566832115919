.modalContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 0px;

  hr {
    border: 1px solid #0000002d;
    margin: 22px 0px;
    width: 100%;
  }

  .addToWaitingList {
    display: flex;
    flex-direction: column;
    align-items: center;

    h4 {
      font-size: 32px;
      font-weight: 600;
      line-height: 41.86px;
      color: #0a0a0a;
      margin-bottom: 10px;
    }

    .btns {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      width: 100%;

      button {
        height: 48px;
        width: 60%;
        background: #d00254;
        border: none;
        font-size: 20px;
        color: #ffffff;
        border-radius: 22px;

        &:nth-child(2) {
          background: rgba(222, 20, 104, 0.15);
          border: 1px solid #d00254;
          color: #d00254;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .modalContainer {
    hr {
      margin: 15px 0px;
    }
    .addToWaitingList {
      h4 {
        font-size: 22px;
        margin-bottom: 5px;
      }
      .btns {
        button {
          font-size: 16px;
          height: 35px;
        }
      }
    }
  }
}
