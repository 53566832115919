.mainContainer {
  height: 100vh;
  width: 100%;
  position: fixed;
  padding: 20px;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;

  .backdrop {
    height: 100vh;
    width: 100%;
    position: fixed;
    left: 0px;
    top: 0px;
    backdrop-filter: brightness(40%) blur(3px);
    -webkit-backdrop-filter: brightness(40%) blur(3px);
  }

  .container {
    border-radius: 22px;
    padding: 20px 20px;
    margin: 0px 0px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.1);
    background: rgba(255, 255, 255, 1);
    min-height: 346px;
    width: 100%;
    max-width: 560px;
    .content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}

@media screen and (max-width: 768px) {
  .mainContainer {
    align-items: center;
    z-index: 999999;
    .container {
      max-width: 500px;
      border-radius: 20px;
      height: auto;
    }
  }
}
