.mainContainer {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 99999999999999;

  .sideMenu {
    position: fixed;
    top: 0;
    width: 300px;
    height: 100vh;
    background-color: #f8f8f8;
    transition: right 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    padding: 10vh 20px 20px 20px;
    z-index: 999999999999;
    box-shadow: 0px 14px 34px rgba(162, 162, 165, 0.4);

    span {
      position: absolute;
      top: 20px;
      right: 20px;
      font-size: 40px;
      cursor: pointer;
    }

    .user {
      display: flex;
      align-items: center;
      gap: 15px;
      margin-bottom: 25px;

      img {
        border-radius: 50%;
        width: 50px;
        height: 50px;
        object-fit: cover;
      }

      p {
        font-size: 20px;
        color: #292d32;
      }
    }

    hr {
      margin-bottom: 25px;
      border: 1px solid #ebebeb;
    }

    nav {
      margin-bottom: 25px;

      margin: 20px 0px 45px 0px;

      ul {
        display: flex;
        flex-direction: column;
        gap: 20px;
        list-style: none;

        a {
          text-decoration: none;

          li {
            font-size: 16px;
            line-height: 23px;
            letter-spacing: 0px;
            color: #292d32;
            cursor: pointer;
            transition: all 300ms ease-in-out;
          }
        }

        .logout {
          display: flex;
          align-items: center;
          gap: 8px;

          img {
            width: 15px;
            height: 15px;
            transform: translateY(-1px);
          }
        }
      }
    }

    .btns {
      display: flex;
      flex-direction: column;

      h4 {
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 15px;

        background: linear-gradient(89deg, #CC1480 21%, #D92776 22%, #D92776 40%, #E6396C 41%, #E6396C 59%, #F24C62 60%, #F24C62 78%, #F24C62 79%), #D92776;
        background-size: 100%;
        background-repeat: repeat;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .row {
        display: flex;
        align-items: center;
        gap: 8px;

        img {
          cursor: pointer;
          width: 105px;
          height: 31px;
        }
      }
    }
  }
}

.sideMenu.open {
  right: 0;
}