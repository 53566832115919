.container {
  width: 100%;
  min-height: 100vh;
  background-color: rgb(243, 244, 246/1);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  padding-top: 50px;

}
@media screen and (max-width: 768px) {
  .container {
    padding: 20px 10px 20px 10px;

    .wrapper {
      display: flex;
      flex-direction: column;
      padding: 0px 0px 60px 0px;
    }
  }
}
