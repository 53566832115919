@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Hebrew:wght@700;800;900&display=swap");

@font-face {
  font-family: "ploni";
  font-weight: 400; /*(regular)*/
  font-style: normal;
  src: url("./fonts/ploni-regular-aaa.woff2") format("woff2"),
    url("./fonts/ploni-regular-aaa.woff") format("woff"),
    url("./fonts/ploni-regular-aaa.eot") format("eot");
}

@font-face {
  font-family: "ploni";
  font-weight: 600; /*(semi-bold)*/
  font-style: normal;
  src: url("./fonts/ploni-demibold-aaa.woff2") format("woff2"),
    url("./fonts/ploni-demibold-aaa.woff") format("woff"),
    url("./fonts/ploni-demibold-aaa.eot") format("eot");
}

@font-face {
  font-family: "ploni";
  font-weight: 700; /*(bold)*/
  font-style: bold;
  src: url("./fonts/ploni-black-aaa.woff2") format("woff2"),
    url("./fonts/ploni-black-aaa.woff") format("woff"),
    url("./fonts/ploni-black-aaa.eot") format("eot");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "ploni", sans-serif;
}

html,
body {
  font-family: "ploni", sans-serif;
  direction: rtl;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
small,
button,
input {
  font-family: "ploni", sans-serif;
}

button {
  font-family: "ploni", sans-serif;

  p {
    font-family: "ploni", sans-serif;
  }
}

:root {
  --primary-bg-color: #f5f5f5;
}

button {
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed !important;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input, select, button {
  outline: none;
}