.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 10px;
  width: 100%;

  svg {
    margin-bottom: 15px;
  }

  h2 {
    font-size: 32px;
    line-height: 41.86px;
    font-weight: 600;
    color: #0a0a0a;
  }

  p {
    text-align: center;
    margin-bottom: 18px;
    font-size: 20px;
    line-height: 26.16px;
    color: #0a0a0a;
  }

  .btns {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 20px;
    button {
      width: 50%;
      max-width: 337px;
      height: 48px;
      padding: 10px 10px;
      border: none;
      background: rgba(208, 2, 84, 1);
      border-radius: 50px;
      cursor: pointer;
      font-size: 20px;
      font-weight: 600;
      color: #ffffff;
      &:hover {
        opacity: 0.9;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    svg {
      width: 75px;
      height: 75px;
    }
    h2 {
      font-size: 28px;
    }
    p {
      font-size: 16px;
      line-height: 23px;
    }
    .btns {
      gap: 10px;
      button {
        font-size: 20px;
    height: 50px;
      }
    }
  }
}
