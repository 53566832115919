.message {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffac1c;
  padding: 20px 20px;

  p {
    font-size: 18px;
  }
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 480px) {
}
