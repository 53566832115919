.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 10px;
  width: 100%;
  position: relative;

  span {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 40px;
    line-height: 42px;
  }

  h2 {
    font-size: 32px;
    line-height: 41.86px;
    font-weight: 600;
    color: #0a0a0a;
    margin-bottom: 30px;
  }

  .employee {
    display: flex;
    align-items: baseline;
    gap: 20px;
    width: 100%;

    img {
      width: 30px;
      height: 30px;
      transform: translateY(11px);
    }

    .text {
      h3 {
        color: #0a0a0a;
        font-weight: 600;
        font-size: 26px;
      }

      p {
        color: #8f8f8f;
        font-size: 20px;
      }
    }
  }

  .row {
    display: flex;
    align-items: baseline;
    gap: 20px;
    width: 100%;
    margin-bottom: 15px;

    img {
      width: 30px;
      height: 30px;
      transform: translateY(11px);
    }

    .commentIcon {
      width: 27px;
      height: 27px;
      margin-right: 2px;
    }

    .text {
      h4 {
        color: #0a0a0a;
        font-weight: 600;
        font-size: 22px;
      }

      p {
        color: #8f8f8f;
        font-size: 18px;
      }
    }

    .status {
      margin-top: 10px;
      width: fit-content;
      height: 48px;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px 20px;

      p {
        font-size: 20px;
      }
    }

    .approved {
      border: 1px solid #00a25a;
      background: rgba(0, 162, 90, 0.27);

      p {
        color: #067c48;
        font-weight: 600;
      }
    }

    .declined {
      border: 2px solid #fa3646;
      background: rgba(230, 0, 0, 0.32);

      p {
        color: #e50000;
        font-weight: 600;
      }
    }

    .pending {
      border: 2px solid #ff7518;
      background: #fbceb1;

      p {
        color: #ff7518;
        font-weight: 600;
      }
    }
    .cancel {
      width: 100%;
      margin-top: 10px;
      font-size: 18px;
      color: #e50000;
      cursor: pointer;
    }
  }

  .lastRow {
    margin-bottom: 0px;
  }

  hr {
    border: 1px solid rgb(237, 237, 237);
    width: 100%;
    margin: 20px 0px;
  }
}

@media screen and (max-width: 480px) {
  .container {
    span {
      position: absolute;
      right: 0px;
      top: 10px;
      font-size: 32px;
      line-height: 0px;
    }
  }
}
