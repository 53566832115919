.container {
  width: 100%;
  min-height: 100vh;
  background-color: rgb(243, 244, 246/1);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  padding-top: 50px;

  .wrapper {
    width: 100%;
    max-width: 560px;

    .actionBtn {
      border: none;
      height: 65px;
      width: 100%;
      border-radius: 22px;
      color: #ffffff;
      font-size: 20px;
      cursor: pointer;
      margin-bottom: 30px;
      position: sticky;
      bottom: 50px;
    }

    .links {
      p {
        font-size: 20px;
        font-weight: 600;
        line-height: 34.16px;
        color: #000000;
        text-align: center;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding: 20px 10px 20px 10px;

    .wrapper {
      display: flex;
      flex-direction: column;
      padding: 0px 0px 60px 0px;

      .actionBtn {
        margin-bottom: 25px;
      }

      .links {
        p {
          font-size: 16px;
          line-height: 28px;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    .wrapper {
      .links {
        p {
          font-size: 14px;
          line-height: 26px;
        }
      }
    }
  }
}
