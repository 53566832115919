.mainContainer {
  height: 100vh;
  width: 100%;
  position: fixed;
  padding: 20px;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;

  .backdrop {
    height: 100vh;
    width: 100%;
    position: fixed;
    left: 0px;
    top: 0px;
    backdrop-filter: brightness(40%) blur(3px);
    -webkit-backdrop-filter: brightness(40%) blur(3px);
  }
  
  .container {
    width: 100%;
    max-width: 440px;
    height: auto;
    background: #ffffff;
    border-radius: 10px;
    padding: 5px;
    margin: 0px 0px;
    position: relative;
    display: flex;
    flex-direction: column;

    .close {
      color: #ffffff;
      font-size: 40px;
      position: absolute;
      top: -50px;
      right: 0px;
    }

    img {
      border-radius: 10px;
    }
  }
}
