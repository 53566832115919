.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 25px;

  .imageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .cover {
      width: 100%;
      height: 199px;
      object-fit: cover;
      border-radius: 22px;
    }
    .profile {
      box-shadow: 0px 4px 34px 0px #0000001a;
      border: 2px solid #ffffff;
      border-radius: 50%;
      width: 173px;
      height: 170px;
      object-fit: cover;
      transform: translateY(-100px);
      margin-bottom: -75px;
    }
  }

  h3 {
    color: #000000;
    font-size: 16px;
    line-height: 20.93px;
    margin-bottom: 8px;
  }
  p {
    font-size: 14px;
    line-height: 20.93px;
    font-weight: 400;
    text-align: center;
    width: 100%;
    max-width: 554px;
    color: #313131;
  }
}

@media screen and (max-width: 768px) {
  .container {
    .imageContainer {
      .cover {
        height: 160px;
      }
      .profile {
        width: 140px;
        height: 143px;
        transform: translateY(-90px);
        margin-bottom: -70px;
      }
    }
  }
}
