.container {
  width: 100%;
  min-height: 100vh;
  background-color: rgb(243, 244, 246/1);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;

  .wrapper {
    width: 100%;
    max-width: 560px;

    .makeAppointmentBtn {
      width: 100%;
      max-width: 560px;
      height: 62px;
      border: none;
      border-radius: 22px;
      font-size: 20px;
      color: #ffffff;
      position: sticky;
      bottom: 15px;

      animation-name: fade;
      animation-duration: 700ms;
      animation-iteration-count: 1;
      animation-direction: normal;

      @keyframes fade {
        0% {
          opacity: 0;
          transform: translateY(100px);
        }
        100% {
          opacity: 1;
          transform: translateY(0px);
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .container {
    padding: 20px 10px 20px 10px;

    .wrapper {
      display: flex;
      flex-direction: column;
      padding: 0px 0px 60px 0px;
    }
  }
}
