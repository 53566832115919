.header {
  height: 90px;
  background-color: #140635;
  display: flex;
  align-items: center;
  justify-content: center;

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    position: relative;
    padding: 0px 20px;

    .hamburger {
      cursor: pointer;
    }

    .logo {
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      cursor: pointer;
      width: 133.01px;
      height: 54.99px;
    }

    button {
      background: #ffffff;
      border-radius: 22px;
      border: none;
      width: 143px;
      height: 44px;
      color: #060a35;
      font-size: 16px;
      font-weight: 600;
      cursor: pointer;
      transition: all 300ms ease-in-out;

      &:hover {
        opacity: 0.9;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .header {
    .wrapper {
      .logo {
        position: relative;
        margin: 0;
      }
      button {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .header {
    height: 60px;
    .wrapper {
      .logo {
        position: relative;
        margin: 0;
        width: 106.4px;
        height: 44px;
      }
      .hamburger {
        width: 33.15px;
        height: 20.4px;
      }
      button {
        display: none;
      }
    }
  }
}
