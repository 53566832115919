.container {
  width: 100%;
  max-width: 560px;
  background: #ffffff;
  border-radius: 22px;
  box-shadow: 0px 4px 34px 0px #0000001a;

  .head {
    height: 114px;
    border-radius: 22px 22px 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
      line-height: 41.86px;
      font-size: 32px;
      color: #ffffff;
      font-weight: normal;
    }

    p {
      line-height: 26.16px;
      font-size: 20px;
      font-weight: 400;
      color: #ffffff;
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    padding: 0px 20px;

    .store {
      padding: 20px 0px;
    }

    hr {
      border: 1px solid rgb(220, 220, 220);
      width: 100%;
      margin-bottom: 20px;
    }

    .appointment {
      display: flex;
      flex-direction: column;
      gap: 35px;
      padding-bottom: 30px;

      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .text {
          display: flex;
          flex-direction: column;

          h5 {
            line-height: 26.16px;
            font-size: 20px;
            color: #000000;
            font-weight: 600;
          }

          p {
            line-height: 26.16px;
            font-size: 20px;
            color: #0a0a0a;
          }
        }

        button {
          border: 1px solid #de1468;
          background-color: transparent;
          width: 151px;
          height: 48px;
          border-radius: 22px;
          font-size: 20px;
          color: #de1468;
          cursor: pointer;
        }
      }

      .bottom {
        display: flex;
        flex-direction: column;
        align-items: center;

        .status {
          width: 100%;
          max-width: 355px;
          height: 48px;
          border-radius: 22px;
          display: flex;
          justify-content: center;
          align-items: center;

          p {
            font-size: 20px;
          }
        }

        .approved {
          border: 1px solid #00a25a;
          background: rgba(0, 162, 90, 0.27);

          p {
            color: #067c48;
            font-weight: 600;
          }
        }

        .declined {
          border: 2px solid #fa3646;
          background: rgba(230, 0, 0, 0.32);

          p {
            color: #e50000;
            font-weight: 600;
          }
        }

        .action {
          display: flex;
          flex-direction: column;
          align-items: center;

          p {
            color: #0a0a0a;
            font-size: 20px;
            line-height: 26.16px;
            margin-bottom: 15px;
          }

          .btns {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;

            button {
              border-radius: 22px;
              border: none;
              height: 48px;
              font-size: 20px;
              font-weight: 600;
              color: #ffffff;
              cursor: pointer;
            }

            .approveBtn {
              background-color: #00a25a;
              width: 155px;
            }

            .declineBtn {
              width: 183px;
              background-color: #e50000;
            }
          }
        }
      }
    }
  }

  .spinner {
    background-color: #d4d4d4;
    width: fit-content;
    align-self: center;
    padding: 5px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 480px) {
  .container {
    .head {
      height: 90px;

      h2 {
        font-size: 26px;
        line-height: 35px;
      }

      p {
        font-size: 18px;
        line-height: 24px;
      }
    }

    .body {
      padding: 0px 15px;

      .appointment {
        .top {
          .text {
            h5 {
              font-size: 16px;
              line-height: 22px;
            }

            p {
              font-size: 14px;
              line-height: 20px;
            }
          }

          button {
            width: 100px;
            height: 34px;
            font-size: 16px;
          }
        }

        .bottom {
          .status {
            height: 40px;

            p {
              font-size: 16px;
            }
          }

          .action {
            width: 100%;

            p {
              font-size: 16px;
              margin-bottom: 12px;
            }

            .btns {
              width: 100%;

              button {
                height: 40px;
                font-size: 16px;
                width: 50% !important;
              }
            }
          }
        }
      }
    }
  }
}
