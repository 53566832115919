// active
.cal1 .react-calendar__tile--active {
  background: #d00254 !important;
}
.cal2 .react-calendar__tile--active {
  background: #007bff !important;
}
.cal3 .react-calendar__tile--active {
  background: #e273AA !important;
}
.cal4 .react-calendar__tile--active {
  background: #111827 !important;
}
.cal5 .react-calendar__tile--active {
  background: #28a745 !important;
}
.cal6 .react-calendar__tile--active {
  background: #c82333 !important;
}
.cal7 .react-calendar__tile--active {
  background: #fd7e14 !important;
}
.cal8 .react-calendar__tile--active {
  background: #9932cc !important;
}
.cal9 .react-calendar__tile--active {
  background: #884a39 !important;
}
.calDefault .react-calendar__tile--active {
  background: #d00254 !important;
}

.react-calendar {
  width: 100% !important;
  max-width: 500px !important;
  font-family: "Noto Sans Hebrew", sans-serif !important;
  background: #270c660d !important;
  padding: 30px 30px 40px 30px !important;
  border-radius: 22px !important;
}

.react-calendar__month-view__weekdays__weekday {
  text-align: center;
  abbr {
    font-size: 20px !important;
    font-weight: 400 !important;
    text-decoration: none;
    font-family: "Noto Sans Hebrew", sans-serif !important;
    color: #656565;
  }
}

.react-calendar__tile.react-calendar__month-view__days__day {
  height: 50px;
  border-radius: 5px;
  border: none;
  background-color: transparent;
  font-family: "Noto Sans Hebrew", sans-serif !important;

  abbr {
    font-size: 20px !important;
    letter-spacing: 0px;
    color: #000;
    font-family: "Noto Sans Hebrew", sans-serif !important;
  }

  &:disabled {
    background-color: transparent;

    abbr {
      color: #bdbdbd;
      font-family: "Noto Sans Hebrew", sans-serif !important;
    }
  }
}

.react-calendar__month-view__days__day--weekend {
  color: #5e6167;
  font-family: "Noto Sans Hebrew", sans-serif !important;
}

// hovered or focused
// .react-calendar__tile--active:enabled:hover,
// .react-calendar__tile--active:enabled:focus {
//   background: #111827 0% 0% no-repeat padding-box !important;
//   border: none;
//   border-radius: 5px;

//   abbr {
//     color: #ffffff !important;
//   }
// }

// active
.react-calendar__tile--active {
  // background: #de1468 0% 0% no-repeat padding-box !important;
  border: none !important;
  font-family: "Noto Sans Hebrew", sans-serif !important;

  abbr {
    color: #ffffff !important;
    font-family: "Noto Sans Hebrew", sans-serif !important;
  }
}

// current day
.react-calendar__tile--now {
  background: transparent 0% 0% no-repeat padding-box;
  font-family: "Noto Sans Hebrew", sans-serif !important;

  abbr {
    color: #000;
    font-family: "Noto Sans Hebrew", sans-serif !important;
  }
}

// current day but disabled
.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend {
  background-color: transparent !important;

  abbr {
    color: #bdbdbd !important;
    font-family: "Noto Sans Hebrew", sans-serif !important;
  }
}

.react-calendar__navigation {
  padding: 0px 20px 0px 4px !important;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.react-calendar__navigation__label {
  display: flex;
  align-items: center;
  order: 1;
  border: none;
  span {
    color: #0a0a0a !important;
    font-size: 24px !important;
  }
}

.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
  font-size: 18px;
  letter-spacing: 0px;
  line-height: 0px;
  color: #292929;
  font-weight: bold;
  padding-left: 14px;
  font-family: "Noto Sans Hebrew", sans-serif !important;
}

// prev
.react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
  order: 2;
  transform: rotate(180deg);
  margin-left: -10px;
  font-size: 30px;
  color: #0a0a0a;
  border: none;
  background-color: transparent !important;
}

.react-calendar__navigation__arrow.react-calendar__navigation__next-button {
  order: 3;
  background-color: transparent !important;
  transform: rotate(180deg);
  margin-right: 19px;
  font-size: 30px;
  color: #0a0a0a;
  border: none;
}

@media screen and (max-width: 768px) {
  .react-calendar {
    max-width: 100% !important;
    padding: 20px 10px 20px 10px !important;
  }

  .react-calendar__month-view__weekdays__weekday {
    abbr {
      font-size: 14px;
    }
  }

  .react-calendar__tile.react-calendar__month-view__days__day {
    abbr {
      font-size: 18px;
    }
  }
}

@media screen and (max-width: 480px) {
  .react-calendar__navigation {
    margin-bottom: 8px;
  }
  .react-calendar__navigation__label {
    span {
      font-size: 18px !important;
    }
  }

  .react-calendar__month-view__weekdays__weekday {
    abbr {
      font-size: 16px !important;
    }
  }

  .react-calendar__tile.react-calendar__month-view__days__day {
    height: 40px;
    abbr {
      font-size: 16px !important;
    }
  }
}
