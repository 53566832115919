.modalContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0px;
  max-height: 700px;
  overflow-y: auto;

  .modalWrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      color: #0a0a0a;
      font-size: 32px;
      font-weight: 600;
      line-height: 41.86px;
      text-align: center;
      margin-bottom: 20px;
    }
    .reminder {
      color: #0a0a0a;
      font-size: 20px;
      line-height: 26.16px;
      text-align: center;
      margin-bottom: 45px;
    }

    .formWrapper {
      width: 100%;
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;

      .details {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
        margin-bottom: 30px;
        margin-top: 40px;

        .row {
          width: 100%;
          display: flex;
          align-items: center;
          gap: 12px;
          // justify-content: space-betwee;

          h4 {
            color: #0a0a0a;
            font-size: 22px;
            font-weight: 600;
            line-height: 23.16px;
          }
        }
      }

      .formHeading {
        margin-bottom: 15px;
        color: #0a0a0a;
        font-size: 26px;
        font-weight: 600;
        line-height: 26.16px;
      }

      .cardForm {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        margin-bottom: 15px;

        .formGroup {
          display: flex;
          flex-direction: column;
          gap: 8px;
          width: 48%;

          .label {
            font-size: 20px;
            font-weight: 600;
            line-height: 26px;
            letter-spacing: 0em;
            color: #0a0a0a;
          }

          .input {
            padding: 10px;
            width: 100%;
            height: 48px;
            border-radius: 12px;
            background: #f9f9f9;
            border: 1px solid #d3d3d3;

            font-size: 16px;
            font-weight: 600;
            line-height: 21px;
            letter-spacing: 0em;
            color: #a7a7a7;

            transition: all 250ms ease-in-out;

            &:focus {
              background: #ffffff;
              border: 1px solid #de1468;
              box-shadow: 0px 4px 34px 0px #0000001a;
            }
          }
        }
      }
      .errorMessage {
        color: #de1468;
        text-align: center;
      }
      .submitBtn {
        border: none;
        cursor: pointer;
        width: 100%;
        height: 44px;
        border-radius: 22px;
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: 0em;
        color: #ffffff;
        margin-top: 10px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .modalContainer {
    max-height: 580px;
    .modalWrapper {
      h2 {
        font-size: 24px;
        line-height: 32px;
      }
      .reminder {
        font-size: 16px;
        margin-bottom: 25px;
      }
      .formWrapper {
        .cardForm {
          .formGroup {
            width: 100%;
          }
        }
      }
    }
  }
}
