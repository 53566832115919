.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
  animation-name: fade;
  animation-duration: 700ms;
  animation-iteration-count: 1;
  animation-direction: normal;

  @keyframes fade {
    0% {
      opacity: 0;
      transform: translateY(-100px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  h2 {
    line-height: 41.86px;
    font-size: 32px;
    font-weight: 600;
    color: #0a0a0a;
  }

  .mainText {
    line-height: 26.16px;
    font-size: 20px;
    color: #0a0a0a;
    font-weight: 400;
    margin-bottom: 30px;
  }

  .card {
    width: 100%;
    min-height: 300px;
    box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.1);
    background: rgba(255, 255, 255, 1);
    border-radius: 22px;
    padding: 45px 50px;

    // SUMMARY
    .summary {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;

      .employee {
        display: flex;
        gap: 10px;
        align-items: center;
        img {
          width: 40px;
          height: 40px;
          border-radius: 12px;
          object-fit: cover;
        }

        p {
          font-size: 16px;
          // transform: translateY(10px);
          color: #0a0a0a;
          text-align: center;
        }
      }

      .line {
        height: 63.5px;
        border: 1px solid #060a3542;
      }

      .service {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 15px;
        text-align: center;

        h5 {
          font-weight: 600;
          color: #0a0a0a;
          font-size: 20px;
          line-height: 26.16px;
        }

        p {
          font-size: 15px;
          line-height: 19.62px;
          color: #000000;
        }
      }

      .serviceOthers {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 0 10px;

        .block {
          height: 27px;
          border-radius: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;

          p {
            font-weight: 600;
            font-size: 16px;
            margin-bottom: -2px;
          }

          &:nth-child(1) {
            min-width: 103px;
          }

          &:nth-child(2) {
            min-width: 76px;
          }
        }
      }
    }

    // CALENDAR
    .calendar {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;

      h4 {
        color: #0a0a0a;
        font-size: 24px;
        font-weight: 600;
        line-height: 31.39px;
        margin-bottom: 12px;
      }
    }

    // TIMESLOTS
    .slots {
      display: flex;
      flex-direction: column;

      h4 {
        color: #0a0a0a;
        font-size: 24px;
        font-weight: 600;
        line-height: 31.39px;
        margin-bottom: 12px;
      }

      .items {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 15px;

        .slot {
          width: 100px;
          min-height: 33px;
          height: auto;
          border-radius: 32px;
          padding: 2px 0px;
          border: 1px solid #0a0a0a;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transition: all 300ms ease-in-out;

          p {
            font-weight: 600;
            color: #0a0a0a;
            font-size: 20px;
            text-align: center;
            margin-bottom: -1px;
            transition: all 300ms ease-in-out;
          }
        }
      }

      .waitingListSignup {
        p {
          font-size: 18px;
          line-height: 32px;
        }

        button {
          border: none;
          height: 40px;
          width: fit-content;
          padding: 0px 15px;
          border-radius: 10px;
          color: #ffffff;
          font-size: 16px;
          cursor: pointer;
        }
      }
    }
  }
}

@media screen and (max-width: 540px) {
  .container {
    .card {
      padding: 25px 30px;

      .summary {
        .employee {
          gap: 8px;

          img {
            width: 60px;
            height: 60px;
          }

          p {
            font-size: 14px;
            // transform: translateY(0);
          }
        }

        .line {
          height: 50px;
        }

        .service {
          padding: 0 0;
          h5 {
            font-size: 18px;
            line-height: 100%;
          }

          p {
            font-size: 13px;
            line-height: 100%;
          }
        }

        .serviceOthers {
          gap: 6px;

          .block {
            height: 22px;
            gap: 5px;

            p {
              font-size: 12px;
              margin-bottom: -2px;
            }

            svg {
              width: 11px;
              height: 11px;
            }

            &:nth-child(1) {
              min-width: 80px;
              max-width: 95px;
            }

            &:nth-child(2) {
              min-width: 70px;
              max-width: 85px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    margin-bottom: 30px;

    h2 {
      line-height: 38.86px;
      font-size: 26px;
    }

    .mainText {
      line-height: 24.16px;
      font-size: 18px;
    }

    .card {
      padding: 20px 15px;

      .slots {
        h4 {
          font-size: 20px;
          margin-bottom: 8px;
        }

        .items {
          gap: 10px;

          .slot {
            width: calc(33.333% - 10px);
            min-height: 33px;

            p {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .container {
    .card {
      .summary {
        justify-content: center;
        gap: 10px;

        .employee {
          gap: 6px;

          img {
            width: 40px;
            height: 40px;
          }

          p {
            font-size: 16px;
            text-align: center;
          }
        }

        .service {
          padding: 0 0;
          h5 {
            font-size: 16px;
            line-height: 100%;
            text-align: center;
          }

          p {
            font-size: 12px;
            line-height: 100%;
            text-align: center;
          }
        }

        .line {
          margin: 0px 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .container {
    h2 {
      line-height: 35.86px;
    }

    .mainText {
      line-height: 20.16px;
    }
  }
}
