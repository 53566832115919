.store {
  display: flex;
  flex-direction: column;
  align-items: center;

  h4 {
    font-weight: 600;
   
    text-align: center;
    background: linear-gradient(89deg, #CC1480 21%, #D92776 22%, #D92776 40%, #E6396C 41%, #E6396C 59%, #F24C62 60%, #F24C62 78%, #F24C62 79%), #D92776;
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .headingOne {
    font-size: 24px;
    line-height: 31.39px;
    background: linear-gradient(89deg, #CC1480 21%, #D92776 22%, #D92776 40%, #E6396C 41%, #E6396C 59%, #F24C62 60%, #F24C62 78%, #F24C62 79%), #D92776;
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .headingTwo {
    font-size: 16px;
    line-height: 20.93px;
    background: linear-gradient(89deg, #CC1480 21%, #D92776 22%, #D92776 40%, #E6396C 41%, #E6396C 59%, #F24C62 60%, #F24C62 78%, #F24C62 79%), #D92776;
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  p {
    font-size: 16px;
    line-height: 20.93px;
    color: #000000;
    text-align: center;
  }
  .btns {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    img {
      cursor: pointer;
      width: 105px;
      height: 31px;
    }
  }
}

@media screen and (max-width: 480px) {
  .store {
    h4 {
      font-size: 20px;
      background: linear-gradient(89deg, #CC1480 21%, #D92776 22%, #D92776 40%, #E6396C 41%, #E6396C 59%, #F24C62 60%, #F24C62 78%, #F24C62 79%), #D92776;
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    }
    p {
      font-size: 13px;
      line-height: 18px;
    }
  }
}
