.outer {
  margin: auto;
  background: #ffffff;
  height: 100px;
  width: 100px;
  border-radius: 15px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
}

.middle {
  height: 60px;
  width: 60px;
  margin: auto;
  position: relative;
  top: 20px;
  border-radius: 50%;
  background-image: linear-gradient(150deg, transparent 50%, #000 50%),
    linear-gradient(90deg, #000 50%, white 50%);
  -webkit-animation: rotation 1200ms infinite linear;
  animation: rotation 1200ms infinite linear;
  transform-origin: 50% 50%;
  animation-timing-function: ease;
}

.inner {
  background: #ffffff;
  height: 48px;
  width: 48px;
  margin: auto;
  position: relative;
  top: 6px;
  border-radius: 50%;
}

@keyframes rotation {
  from {
    transform: rotate(270deg);
  }

  to {
    transform: rotate(630deg);
  }
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(270deg);
  }

  to {
    -webkit-transform: rotate(630deg);
  }
}
