.modalContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;

  .modalWrapper {
    height: 100%;
    width: 100%;
    max-width: 346px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2 {
      color: #0a0a0a;
      font-size: 32px;
      font-weight: 600;
      line-height: 41.86px;
      text-align: center;
    }
    .reminder {
      color: #0a0a0a;
      font-size: 20px;
      line-height: 26.16px;
      text-align: center;
      margin-bottom: 45px;
    }
    .cards {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 20px;
      width: 100%;
      margin-bottom: 45px;
      .card {
        width: 163px;
        height: 121px;
        border-radius: 22px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        h3 {
          line-height: 31.39px;
          font-size: 24px;
          font-weight: 600;
          text-align: center;
          color: #000000;
        }
        p {
          font-weight: 600;
          font-size: 16px;
          line-height: 20.93px;
          text-align: center;
        }
      }
    }

    .comment {
      display: flex;
      flex-direction: column;
      gap: 18px;
      width: 100%;
      margin-bottom: 25px;

      input {
        width: 100%;
        background: transparent;
        border: 2px solid #786a5133;
        padding: 12px 16px 12px 16px;
        border-radius: 22px;
        height: 44px;
        color: #958870;
        font-size: 16px;
      }

      .amountToPayText {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px;
        background-color: #00a2592c;
        border: 1px solid #00a25a;
        border-radius: 5px;
      }

      button {
        background-color: #00a25a;
        border-radius: 22px;
        border: none;
        font-size: 20px;
        color: #ffffff;
        height: 48px;
        width: 100%;
        cursor: pointer;
      }
    }

    .policy {
      margin-bottom: 20px;
      font-size: 14px;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 480px) {
  .modalContainer {
    .modalWrapper {
      h2 {
        font-size: 24px;
        line-height: 32px;
      }
      .reminder {
        font-size: 16px;
        margin-bottom: 25px;
      }
      .cards {
        margin-bottom: 25px;
        gap: 15px;
        .card {
          width: calc(50% - 15px);
          height: 110px;
          h3 {
            font-size: 18px;
            line-height: 26px;
          }
        }
      }
      .comment {
        margin-bottom: 25px;
      }
    }
  }
}
