.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 80px;
  transition: all 300ms ease-in-out;

  animation-name: fade;
  animation-duration: 700ms;
  animation-iteration-count: 1;
  animation-direction: normal;

  @keyframes fade {
    0% {
      opacity: 0;
      transform: translateY(-100px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  .noServices {
    line-height: 26.16px;
    font-size: 24px;
    color: #0a0a0a;
    font-weight: 400;
  }

  h2 {
    line-height: 41.86px;
    font-size: 32px;
    font-weight: 600;
    color: #0a0a0a;
  }

  .mainText {
    line-height: 26.16px;
    font-size: 20px;
    color: #0a0a0a;
    font-weight: 400;
    margin-bottom: 30px;
  }

  .services {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 25px;
    width: 100%;

    .card {
      width: 100%;
      height: auto;
      min-height: 116px;
      border-radius: 22px;
      padding: 20px 30px;
      display: flex;
      align-items: center;
      box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.1);
      background: rgba(255, 255, 255, 1);
      border: 1px solid rgba(255, 255, 255, 1);
      cursor: pointer;
      transition: all 300ms ease-in-out;
      justify-content: space-between;
      .img {
        width: 80px;
        height: 80px;
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 15px;

        h3 {
          font-size: 40px;
          font-weight: 600;
          color: rgba(255, 255, 255, 1);
        }
      }

      .content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        max-width: 60%;
        .name {
          font-weight: 600;
          font-size: 20px;
          line-height: 26.16px;
          color: #0a0a0a;
        }

        .desc {
          font-size: 15px;
          line-height: 19.62px;
          margin-bottom: 8px;
          color: #000000;
        }

        .row {
          display: flex;
          align-items: center;
          gap: 16px;

          .block {
            height: 27px;
            border-radius: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;

            p {
              font-weight: 300;
              font-size: 16px;
              margin-bottom: -2px;
            }

            &:nth-child(1) {
              min-width: 103px;
            }

            &:nth-child(2) {
              min-width: 76px;
            }
          }
        }
      }

      button {
        height: 77px;
        width: 71px;
        border-radius: 22px;
        
        background: rgb(255, 255, 255);
        padding: 23px, 15px, 24px, 15px;
        font-size: 16px;
        font-weight: 500;
        color: rgba(255, 255, 255, 1);
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    // FLOW 2
    .flow2 {
      display: flex;

      .block {
        height: 27px;
        border-radius: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        padding: 0px 5px;

        p {
          font-weight: 600;
          font-size: 16px;
          margin-bottom: -2px;
        }

        &:nth-child(1) {
          min-width: 103px;
        }

        &:nth-child(2) {
          min-width: 76px;
        }
      }

      .right {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .row {
          display: flex;

          .name {
            font-weight: 600;
            font-size: 20px;
            line-height: 26.16px;
            color: #0a0a0a;
            margin-top: 7px;
          }

          .desc {
            font-size: 15px;
            line-height: 19.62px;
            margin-bottom: 8px;
            color: #000000;
          }
        }

        .rowTwo {
          align-items: center;
          gap: 12px;
        }
      }

      .left {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .container {
    margin-bottom: 50px;

    h2 {
      line-height: 38.86px;
      font-size: 26px;
    }

    .mainText {
      line-height: 24.16px;
      font-size: 18px;
    }

    .services {
      gap: 20px;

      .card {
        height: auto;
        padding: 20px 15px;
        min-height: 95px;

        .img {
          width: 65px;
          height: 65px;
          border-radius: 12px;
          margin-left: 15px;

          h3 {
            font-size: 30px;
          }
        }

        .content {
          .name {
            font-size: 16px;
            line-height: 22px;
          }

          .desc {
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 5px;
            max-width: 90%;
          }

          .row {
            gap: 10px;

            .block {
              height: 22px;
              gap: 5px;

              p {
                font-size: 12px;
                margin-bottom: -2px;
              }

              svg {
                width: 11px;
                height: 11px;
              }

              &:nth-child(1) {
                min-width: 70px;
              }

              &:nth-child(2) {
                min-width: 80px;
              }
            }
          }
        }

        button {
          height: 50px;
          width: 50px;
          border-radius: 10px;
          padding: 0;
          font-size: 12px;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    h2 {
      line-height: 35.86px;
    }

    .mainText {
      line-height: 20.16px;
    }

    .services {
      .card {
        .content {
          .row {
            .block {
              min-width: auto !important;
              padding: 0px 7px;
            }
          }
        }
      }

      .flow2 {
        .block {
          min-width: auto !important;
          white-space: nowrap;
          svg {
            width: 12px;
            height: 12px;
          }
          p {
            font-size: 12px;
          }
        }
        .right {
          .row {

            .name {
              font-size: 16px;
              line-height: 20px;
              margin-top: 4px;
            }

            .desc {
            font-size: 13px;
            line-height: 16px;
            margin-bottom: 2%;
          }
        }
          .rowTwo {
            gap: 5px;
          }
        }
      }
    }
  }
}
