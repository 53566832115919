.container {
  margin-bottom: 40px;

  .social {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-bottom: 45px;


    a.linkmain {
      text-decoration: none;
      color: inherit;

      .card {
        box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.1);
        width: 173px;
        height: 161px;
        border-radius: 22px;
        transition: all 250ms ease-in-out;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 22px;

        svg {
          width: 57px;
          height: 57px;
          transition: all 250ms ease-in-out;
        }

        p {
          font-size: 16px;
          line-height: 20.93px;
          color: rgba(0, 0, 0, 1);
          font-weight: 600;
          transition: all 250ms ease-in-out;
        }
      }
    }
  }

  .btns {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;

    .address {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      background-color: #ffffff;
      padding: 4px 10px;
      border-radius: 8px;

      svg {
        width: 15px;
        height: 15px;
      }
    }

    button {
      border-radius: 22px;
      width: 100%;
      height: 44px;
      background-color: transparent;
      cursor: pointer;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        width: 100%;
        height: 100%;
        text-decoration: none;
        color: unset;

        p {
          font-size: 20px;
          font-weight: 600;
          transform: translateY(1px);
        }
      }

      &:nth-child(1) {
        border: 1px solid rgba(6, 10, 53, 1);
        max-width: 241px;

        a {
          p {
            color: rgba(6, 10, 53, 1);
          }
        }
      }

      &:nth-child(2) {
        border: 1px solid #de1468;
        max-width: 294px;

        p {
          color: #de1468;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    .social {
      gap: 10px;
    }

    .btns {
      gap: 10px;
    }
  }
}

@media screen and (max-width: 540px) {
  .container {
    .social {
      .card {
        width: 143px;
        height: 141px;
        padding: 5px;

        div {
          gap: 16px;

          svg {
            width: 40px;
            height: 40px;
          }

          p {
            font-size: 15px;
            text-align: center;
            line-height: 18.93px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    .social {
      margin-bottom: 40px;

      .card {
        width: 133px !important;
        height: 120px !important;

        div {
          gap: 14px;

          svg {
            width: 38px;
            height: 38px;
          }
        }
      }
    }

    .btns {
      button {
        height: 40px;

        a {
          p {
            font-size: 16px;
          }

          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .container {
    .social {
      .card {
        height: 105px;

        div {
          gap: 13px;

          svg {
            width: 30px;
            height: 30px;
          }
        }
      }
    }

    .btns {
      button {
        height: 40px;
        gap: 8px;

        a {
          p {
            font-size: 16px;
          }

          svg {
            width: 18px;
            height: 18px;
          }
        }
      }
    }
  }
}