.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;

  h2 {
    line-height: 41.86px;
    font-size: 32px;
    font-weight: 600;
    color: #0a0a0a;
  }
  p {
    line-height: 26.16px;
    font-size: 20px;
    color: #0a0a0a;
    font-weight: 400;
    margin-bottom: 20px;
  }
  .images {
    // display: flex;
    // gap: 12px;
    width: 100%;
    // overflow-x: auto;

    img {
      width: calc(33.333% - 10px);
      min-width: calc(33.333% - 10px);
      // width: 33.333%;
      // min-width: 33.333%;
      height: 177px;
      object-fit: cover;
      margin-right: 10px !important;
    }

    /* width */
    &::-webkit-scrollbar {
      height: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    .images {
      img {
        width: 33.333%;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    margin-bottom: 45px;
    h2 {
      line-height: 38.86px;
      font-size: 26px;
    }
    p {
      line-height: 24.16px;
      font-size: 18px;
    }
    .images {
      img {
        height: 140px;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .container {
    h2 {
      line-height: 35.86px;
    }
    p {
      line-height: 20.16px;
    }
    .images {
      img {
        height: 110px;
      }
    }
  }
}
