.container {
  width: 100%;
  min-height: 100vh;
  background-color: rgb(243, 244, 246/1);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;

  .wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      line-height: 41.86px;
      font-size: 32px;
      font-weight: 600;
      color: #0a0a0a;
      margin-bottom: 40px;
    }

    .card {
      width: 100%;
      max-width: 560px;
      background: #ffffff;
      border-radius: 22px;
      box-shadow: 0px 4px 34px 0px #0000001a;
      display: flex;
      flex-direction: column;
      align-items: center;

      .head {
        width: 100%;
        border-radius: 22px 22px 0px 0px;
        padding: 20px 10px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .profileImg {
          position: relative;
          width: 173px;
          height: 170px;
          margin-bottom: 20px;
          .profile {
            box-shadow: 0px 4px 34px 0px #0000001a;
            border: 2px solid #ffffff;
            border-radius: 50%;
            width: 173px;
            height: 170px;
            object-fit: cover;
          }
          .cam {
            position: absolute;
            bottom: 10px;
            left: 10px;
            background-color: #ffffff;
            border-radius: 50%;
            padding: 4px;
            width: 30px;
            height: 30px;
          }
          input {
            position: absolute;
            bottom: 10px;
            left: 10px;
            opacity: 0;
            width: 30px;
            height: 30px;
          }
        }

        h4 {
          font-size: 26px;
          font-weight: 600;
          color: #ffffff;

          span {
            font-size: 20px;
            cursor: pointer;
          }
        }

        .editField {
          input {
            border: none;
            background-color: transparent;
            border-bottom: 1px solid #ffffff;
            font-size: 22px;
            font-weight: 600;
            color: #ffffff;
            padding: 2px 0px;
          }
        }
      }

      .body {
        padding: 30px 10px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .row {
          display: flex;
          align-items: center;
          gap: 10px;

          p {
            color: #8f8f8f;
            font-weight: 400;
            font-size: 24px;
            transform: translateY(1.5px);
          }
        }

        .update {
          background-color: transparent;
          width: 100%;
          height: 48px;
          border-radius: 22px;
          font-size: 20px;
          cursor: pointer;
          margin-top: 10px;
        }

        .delete {
          background-color: #de1468;
          border: none;
          width: 100%;
          height: 48px;
          border-radius: 22px;
          font-size: 20px;
          color: #ffffff;
          cursor: pointer;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding: 20px 10px 20px 10px;

    .wrapper {
      display: flex;
      flex-direction: column;
      padding: 0px 0px 60px 0px;
    }
  }
}
