.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 55px;

  h2 {
    line-height: 41.86px;
    font-size: 32px;
    font-weight: 600;
    color: #0a0a0a;
  }
  .mainText {
    line-height: 26.16px;
    font-size: 20px;
    color: #0a0a0a;
    font-weight: 400;
  }
  
  .card {
    margin-top: 25px;
    height: 100%;
    min-height: 500px;
    width: 100%;
    box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.1);
    background: rgba(255, 255, 255, 1);
    border-radius: 22px;
    padding: 35px 20px 40px 20px;

    // EMPLOYEES
    .employees {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 40px;

      p {
        line-height: 26.16px;
        font-size: 20px;
        margin-bottom: 25px;
      }
      .tabs {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 12px;

        .tab {
          box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.1);
          background: rgba(255, 255, 255, 1);
          width: 97px;
          height: 45px;
          border-radius: 22px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transition: all 250ms ease-in-out;
          p {
            color: #0a0a0a;
            font-size: 16px;
            text-align: center;
    line-height: 100%;
            margin-bottom: 0px;
            transition: all 250ms ease-in-out;
          }
        }
      }
    }

    // DAYS
    .days {
      display: flex;
      flex-direction: column;
      margin: 0px 10px;

      .day {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid rgb(220, 220, 220);
        padding: 28px 20px;

        &:last-child {
          border: none;
          padding-bottom: 0px;
        }

        .name {
          color: rgb(99, 99, 99);
        }

        .timings {
          display: flex;
          flex-direction: column;
          gap: 12px;
          width: 100%;
          max-width: 135px;

          p {
            width: 100%;
            color: rgb(99, 99, 99);
          }

          .time {
            display: flex;
            align-items: center;
            gap: 10px;

            p {
              transform: translateY(1.5px);
            }
          }
        }
      }

      .noSchedule {
        padding: 20px 0px;
        p {
          color: rgb(99, 99, 99);
          text-align: center;
        }
      }
    }

    // IF DAYS LOADING
    .loadingContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 200px;
      .loader {
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgb(220, 220, 220);
        width: fit-content;
        padding: 10px;
        border-radius: 5px;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    margin-bottom: 30px;
    h2 {
      line-height: 38.86px;
      font-size: 26px;
    }
    .mainText {
      line-height: 24.16px;
      font-size: 18px;
    }

    .card {
      padding: 35px 20px 30px 20px;
      min-height: auto;

      // EMPLOYEES
      .employees {
        margin-bottom: 25px;

        p {
          font-size: 16px;
        }
        .tabs {
          gap: 8px;
          .tab {
            width: 87px;
            height: 40px;

            p {
              font-size: 14px;
            }
          }
        }
      }

      // DAYS
      .days {
        .day {
          padding: 22px 10px;
          .name {
            font-size: 14px;
          }

          .timings {
            max-width: 115px;
            p {
              font-size: 14px;
            }

            .time {
              img {
                width: 14px;
                height: 14px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .container {
    h2 {
      line-height: 35.86px;
    }
    .mainText {
      line-height: 20.16px;
    }
  }
}
