.invalidContainer {
  height: 100vh;
  width: 100%;
  display: flex;
  padding-bottom: 100px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 20px 10px 100px 10px;

  img {
    width: 90%;
    max-width: 500px;
    margin-bottom: 20px;
    margin: 0px 10px 20px 10px;
  }

  p {
    text-align: center;
    color: #111827;
    font-size: 18px;
    margin: 0px 10px 10px 10px;
  }

  .border {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%2333333373' stroke-width='1' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    padding: 20px;

    p {
      margin-bottom: 30px;
    }

    button {
      margin: 20px;
      width: 100%;
      max-width: 800px;
      padding: 15px 12px;
      border: none;
      background-color: #111827;
      border-radius: 5px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 600;
      color: #ffffff;

      &:hover {
        opacity: 0.9;
      }
    }

    .imgContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      a {
        text-decoration: none;
        width: 55%;
        max-width: 500px;
        img {
          width: 100%;
          margin: 0px;
          margin-bottom: 10px;
        }
      }
    }
  }
}
