.container {
  width: 100%;
  min-height: 100vh;
  background-color: rgb(243, 244, 246/1);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  padding-top: 220px;

  .card {
    width: 100%;
    max-width: 560px;
    height: 305px;
    padding: 40px;
    margin-bottom: 30px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border-radius: 22px;
    background-color: #ffffff;
    box-shadow: 0px 4px 34px 0px #0000001a;

    h2 {
      color: #0a0a0a;
      font-size: 32px;
      line-height: 41.86px;
      font-weight: 600;
    }

    p {
      font-size: 20px;
      line-height: 26.16px;
      font-weight: 400;
      margin-bottom: 25px;
    }

    input {
      width: 100%;
      max-width: 337px;
      border: 2px solid #786a5133;
      background: #ffffff;
      border-radius: 22px;
      padding: 12px 16px 12px 16px;
      margin-bottom: 20px;

      color: #958870;
      font-size: 16px;
    }

    button {
      width: 100%;
      max-width: 337px;
      padding: 12px 70px 12px 70px;
      color: #ffffff;
      font-size: 20px;
      border-radius: 22px;
      border: none;
      background: #d00254;
      cursor: pointer;

      &:disabled {
        opacity: 1;
      }
    }
  }

  .agree {
    font-size: 12px;
    width: 100%;
    max-width: 433px;
    text-align: center;
    margin-bottom: 20px;
  }

  .noAccount {
    color: #111827;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
  }
}

@media screen and (max-width: 480px) {
  .container {
    padding-top: 100px;
    .card {
      padding: 30px 15px;

      h2 {
        font-size: 26px;
        line-height: 34px;
      }

      p {
        font-size: 18px;
        line-height: 26.16px;
        margin-bottom: 22px;
      }

      input {
        margin-bottom: 15px;
      }
    }
  }
}
